var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 17 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        staticClass: "moon",
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M3.47553 12.7752C3.2652 12.8113 3.09203 12.9618 3.02688 13.1657C2.96229 13.37 3.01601 13.5933 3.16747 13.7445C6.16975 16.7519 11.0444 16.7519 14.0467 13.7445C17.0489 10.737 17.0489 5.85403 14.0467 2.8466C13.8958 2.69488 13.6729 2.64106 13.4688 2.70575C13.2654 2.77102 13.1151 2.9445 13.079 3.15518C12.7538 5.07369 11.6285 7.4857 9.71385 9.40421C7.79863 11.3221 5.39074 12.4494 3.47553 12.7752Z",
          fill: "#8B98AA",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "sun",
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M4.51856 11.2998C5.91939 10.725 7.38595 9.80722 8.64104 8.55055C9.82755 7.362 10.7106 5.98395 11.2884 4.65113C9.41433 3.77404 7.11276 4.1101 5.56675 5.65934C4.04533 7.18338 3.69612 9.4391 4.51856 11.2998ZM0.571536 9.27765H2.50904C2.82453 9.27765 3.08058 9.02116 3.08058 8.70513C3.08058 8.3891 2.82453 8.13261 2.50904 8.13261H0.571536C0.256048 8.13261 0 8.3891 0 8.70513C0 9.02116 0.256048 9.27765 0.571536 9.27765ZM2.54562 3.35953L3.91559 4.73128C4.13849 4.95514 4.50084 4.95514 4.72374 4.73128C4.94664 4.508 4.94664 4.14503 4.72374 3.92174L3.35377 2.54942C3.13087 2.32614 2.76852 2.32614 2.54562 2.54942C2.32272 2.77327 2.32272 3.13568 2.54562 3.35953ZM8.11924 0.572517V2.51278C8.11924 2.82881 8.37528 3.0853 8.69077 3.0853C9.00626 3.0853 9.26231 2.82881 9.26231 2.51278V0.572517C9.26231 0.256488 9.00626 0 8.69077 0C8.37528 0 8.11924 0.256488 8.11924 0.572517Z",
          fill: "#2A59FF",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }