var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "header" }, [
    _c("div", { staticClass: "header-panel" }, [
      _c("div", { staticClass: "header__container" }, [
        _c("div", { staticClass: "header-panel__info info-header-panel" }, [
          _c("div", { staticClass: "info-header-panel__el" }, [
            _c("span", { staticClass: "info-header-panel__title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("common.useful_links")) +
                  ":  \n          "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "doc_link",
                attrs: {
                  href: "/docs/coins_whitepaper.pdf",
                  rel: "nofollow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.whitepaper")) +
                    "\n            "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "doc_link",
                attrs: {
                  href: "/docs/coins_onepager.pdf",
                  rel: "nofollow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.onepager")) +
                    "\n            "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "doc_link",
                attrs: {
                  href: "/docs/coins_pitchdeck.pdf",
                  rel: "nofollow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.pitchdeck")) +
                    "\n            "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "doc_link",
                attrs: {
                  href: "/docs/coins_roadmap.pdf",
                  rel: "nofollow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.roadmap")) +
                    "\n            "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "doc_link",
                attrs: {
                  href: "/docs/coins_tokenomics.pdf",
                  rel: "nofollow noopener noreferrer",
                  target: "_blank",
                },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("common.tokenomics")) +
                    "\n            "
                ),
                _c("i", { staticClass: "bx bx-link-external" }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-header-panel__el" }, [
            _c("span", { staticClass: "info-header-panel__title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("common.marketcap")) +
                  ":  \n          "
              ),
            ]),
            _vm._v(" "),
            _vm.stats !== null && _vm.stats.length > 0 && !_vm.statsLoading
              ? _c("span", { staticClass: "info-header-panel__value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.stats[5].value) +
                      "\n          "
                  ),
                ])
              : _vm.statsLoading
              ? _c(
                  "span",
                  { staticClass: "info-header-panel__value" },
                  [
                    _c("Skeleton", {
                      attrs: { width: "100px", height: "12px" },
                    }),
                  ],
                  1
                )
              : _c("span", { staticClass: "info-header-panel__value" }, [
                  _vm._v("\n            ...\n          "),
                ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-header-panel__el" }, [
            _c("span", { staticClass: "info-header-panel__title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("common.daily_volume")) +
                  ":  \n          "
              ),
            ]),
            _vm._v(" "),
            _vm.stats !== null && _vm.stats.length > 0 && !_vm.statsLoading
              ? _c("span", { staticClass: "info-header-panel__value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.stats[6].value) +
                      "\n          "
                  ),
                ])
              : _vm.statsLoading
              ? _c(
                  "span",
                  { staticClass: "info-header-panel__value" },
                  [
                    _c("Skeleton", {
                      attrs: { width: "100px", height: "12px" },
                    }),
                  ],
                  1
                )
              : _c("span", { staticClass: "info-header-panel__value" }, [
                  _vm._v("\n            ...\n          "),
                ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "info-header-panel__el" }, [
            _c("span", { staticClass: "info-header-panel__title" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("common.gas")) +
                  ":  \n          "
              ),
            ]),
            _vm._v(" "),
            _vm.stats !== null && _vm.stats.length > 0 && !_vm.statsLoading
              ? _c("span", { staticClass: "info-header-panel__value" }, [
                  _vm._v(
                    "\n            " + _vm._s(_vm.gasPrice) + "\n          "
                  ),
                ])
              : _vm.statsLoading
              ? _c(
                  "span",
                  { staticClass: "info-header-panel__value" },
                  [
                    _c("Skeleton", {
                      attrs: { width: "100px", height: "12px" },
                    }),
                  ],
                  1
                )
              : _c("span", { staticClass: "info-header-panel__value" }, [
                  _vm._v("\n            ...\n          "),
                ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "header-panel__actions header-panel-actions" },
          [
            _c(
              "div",
              {
                staticClass:
                  "header-panel-actions__main-actions header-main-actions",
              },
              [
                _c(
                  "div",
                  { staticClass: "header-main-actions__select-wrapper" },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          "header-main-actions__el header-main-actions__el_select": true,
                          _active: _vm.dropdowns[0],
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "header-main-actions__icon",
                            on: {
                              click: function ($event) {
                                return _vm.toggleDropdrown(0)
                              },
                            },
                          },
                          [_c("i", { staticClass: "bx bx-globe" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "header-main-actions__name select": true,
                              "is-active": _vm.dropdowns[0],
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "select__header",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleDropdrown(0)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "select__current" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$i18n.locale.name) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "bx bxs-down-arrow down-icon",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "select__body" },
                              _vm._l(_vm.availableLocales, function (locale) {
                                return _c(
                                  "div",
                                  {
                                    key: locale.code,
                                    staticClass: "select__item",
                                    on: { click: _vm.closeDropdowns },
                                  },
                                  [
                                    _c(
                                      "nuxt-link",
                                      {
                                        attrs: {
                                          to: _vm.switchLocalePath(locale.code),
                                          rel: "alternate",
                                          hreflang: locale.code,
                                          title: locale.name,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(locale.name) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "header-main-actions__select-wrapper" },
                  [
                    _c(
                      "div",
                      {
                        class: {
                          "header-main-actions__el header-main-actions__el_select": true,
                          _active: _vm.dropdowns[1],
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "header-main-actions__icon",
                            on: {
                              click: function ($event) {
                                return _vm.toggleDropdrown(1)
                              },
                            },
                          },
                          [_c("CurrencyIcon")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "header-main-actions__name header-main-actions__name_currency select": true,
                              "is-active": _vm.dropdowns[1],
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "select__header",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleDropdrown(1)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "select__current" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.pickedCurrency.code.toUpperCase()
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "bx bxs-down-arrow down-icon",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "select__body" },
                              _vm._l(_vm.currencies, function (curr, ind) {
                                return _c(
                                  "span",
                                  {
                                    key: ind,
                                    staticClass: "select__item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeCurrency(curr.code)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(curr.code.toUpperCase()) +
                                        "\n                  "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    staticClass:
                      "header-main-actions__el header-main-actions__el",
                    attrs: { to: _vm.localePath("/account") },
                  },
                  [
                    _c("div", { staticClass: "header-main-actions__icon" }, [
                      _c("i", { staticClass: "bx bxs-star" }),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "header-main-actions__name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("common.watchlist")) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "header-main-actions__el header-main-actions__el_theme change-theme",
                    attrs: {
                      type: "button",
                      "aria-label": _vm.$t("common.change_theme"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeTheme(null)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "header-main-actions__icon" },
                      [_c("DayNightIcon")],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "header-main-actions__name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t(`common.${_vm.theme}`)) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.user === null
              ? _c(
                  "div",
                  { staticClass: "header-panel-actions__log" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "header-panel-actions__button stroke-button",
                        attrs: { to: _vm.localePath("/login") },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("common.login")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "header-panel-actions__button filled-button",
                        attrs: { to: _vm.localePath("/register") },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("common.register")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "header-panel-actions__log" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        staticClass:
                          "header-panel-actions__button filled-button",
                        attrs: { to: _vm.localePath("/account") },
                      },
                      [
                        _c("i", { staticClass: "bx bx-user-circle" }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("common.my_account")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "header-panel-actions__button stroke-button",
                        attrs: { type: "button" },
                        on: { click: _vm.logoutFromAccount },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("common.logout")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "header-main" }, [
      _c(
        "div",
        { staticClass: "header__container" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "header-main__logo logo-header",
              attrs: { to: _vm.localePath("/") },
            },
            [
              _c(
                "div",
                { staticClass: "logo-header__desktop" },
                [
                  _vm.theme === "dark"
                    ? _c("nuxt-img", {
                        staticClass: "logo-header__img-dark",
                        attrs: {
                          src: "/design/logo-main-part-dark.svg",
                          alt: _vm.$t("common.logo_alt"),
                          title: _vm.$t("common.logo_title"),
                        },
                      })
                    : _c("nuxt-img", {
                        staticClass: "logo-header__img-light",
                        attrs: {
                          src: "/design/logo-main-part-light.svg",
                          alt: _vm.$t("common.logo_alt"),
                          title: _vm.$t("common.logo_title"),
                        },
                      }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "logo-header__mobile" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.rating_of")) +
                    "\n          "
                ),
                _c("br"),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.products")) +
                    "\n        "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "nav",
            {
              class: {
                "header-main__navigation header-navigation": true,
                _active: _vm.hamburgerActive,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "header-navigation__head-panel burger-head-panel",
                },
                [
                  _c("div", { staticClass: "burger-head-panel__actions" }, [
                    _c(
                      "div",
                      {
                        class: {
                          "header-main-actions__el header-main-actions__el_select": true,
                          _active: _vm.dropdowns[0],
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "header-main-actions__icon",
                            on: {
                              click: function ($event) {
                                return _vm.toggleDropdrown(0)
                              },
                            },
                          },
                          [_c("i", { staticClass: "bx bx-globe" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "header-main-actions__name select": true,
                              "is-active": _vm.dropdowns[0],
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "select__header",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleDropdrown(0)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "select__current" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.$i18n.locale.toUpperCase()) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "bx bxs-down-arrow down-icon",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "select__body" },
                              _vm._l(_vm.availableLocales, function (locale) {
                                return _c(
                                  "div",
                                  {
                                    key: locale.code,
                                    staticClass: "select__item",
                                    on: { click: _vm.closeDropdowns },
                                  },
                                  [
                                    _c(
                                      "nuxt-link",
                                      {
                                        attrs: {
                                          to: _vm.switchLocalePath(locale.code),
                                          rel: "alternate",
                                          hreflang: locale.code,
                                          title: locale.name,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(locale.code.toUpperCase()) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class: {
                          "header-main-actions__el header-main-actions__el_select": true,
                          _active: _vm.dropdowns[1],
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "header-main-actions__icon",
                            on: {
                              click: function ($event) {
                                return _vm.toggleDropdrown(1)
                              },
                            },
                          },
                          [_c("CurrencyIcon")],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class: {
                              "header-main-actions__name header-main-actions__name_currency select": true,
                              "is-active": _vm.dropdowns[1],
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "select__header",
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleDropdrown(1)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "select__current" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.pickedCurrency.code.toUpperCase()
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "bx bxs-down-arrow down-icon",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "select__body" },
                              _vm._l(_vm.currencies, function (curr, ind) {
                                return _c(
                                  "span",
                                  {
                                    key: ind,
                                    staticClass: "select__item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeCurrency(curr.code)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(curr.code.toUpperCase()) +
                                        "\n                  "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "burger-head-panel__buttons" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "actions-main-header__download",
                          attrs: {
                            type: "button",
                            "aria-label": _vm.$t("search.search"),
                          },
                          on: { click: _vm.toggleSearch },
                        },
                        [_c("i", { staticClass: "bx bx-search" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "nuxt-link",
                        {
                          staticClass: "actions-main-header__download",
                          attrs: {
                            to: _vm.localePath("/account"),
                            "aria-label": _vm.$t("common.watchlist"),
                          },
                        },
                        [_c("i", { staticClass: "bx bxs-star" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "header-main-actions__el header-main-actions__el_theme change-theme",
                          attrs: {
                            "aria-label": _vm.$t("common.change_theme"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeTheme(null)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "header-main-actions__icon" },
                            [_c("DayNightIcon")],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("Menu", {
                ref: "mainMenu",
                attrs: { active: _vm.hamburgerActive },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "header-navigation__bottom-panel bottom-panel-navigation",
                },
                [
                  _vm.user === null
                    ? _c(
                        "div",
                        { staticClass: "bottom-panel-navigation__log" },
                        [
                          _c(
                            "nuxt-link",
                            {
                              staticClass:
                                "header-panel-actions__button stroke-button",
                              attrs: { to: _vm.localePath("/register") },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.create_account")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "nuxt-link",
                            {
                              staticClass:
                                "header-panel-actions__button filled-button",
                              attrs: { to: _vm.localePath("/login") },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.login")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "bottom-panel-navigation__log" },
                        [
                          _c(
                            "nuxt-link",
                            {
                              staticClass:
                                "header-panel-actions__button filled-button",
                              attrs: { to: _vm.localePath("/account") },
                            },
                            [
                              _c("i", { staticClass: "bx bx-user-circle" }),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.my_account")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass:
                                "header-panel-actions__button stroke-button",
                              attrs: { type: "button" },
                              on: { click: _vm.logoutFromAccount },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.logout")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bottom-panel-navigation__buttons" },
                    [
                      _c(
                        "span",
                        { staticClass: "bottom-panel-navigation__title" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("common.download")) +
                              "\n              "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("common.app")) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "bottom-panel-navigation__button",
                          attrs: {
                            href: "https://play.google.com/store/apps/details?id=com.coins_rating.twa",
                            rel: "nofollow noopener noreferrer",
                            target: "_blank",
                            "aria-label": _vm.$t(
                              "common.download_app_google_play"
                            ),
                          },
                        },
                        [_c("i", { staticClass: "bx bxl-play-store" })]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "bottom-panel-navigation__button disabled",
                          attrs: {
                            "text-tooltip": _vm.$t("common.coming_soon"),
                            flow: "left",
                          },
                        },
                        [_c("i", { staticClass: "bx bxl-apple" })]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "header-main__actions actions-main-header" },
            [
              _c("SearchPanel", {
                attrs: { active: _vm.searchActive },
                on: { toggle: _vm.toggleSearch },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "actions-main-header__download",
                  attrs: {
                    href: "https://play.google.com/store/apps/details?id=com.coins_rating.twa",
                    rel: "nofollow noopener noreferrer",
                    target: "_blank",
                    "aria-label": _vm.$t("common.download_app_google_play"),
                  },
                },
                [
                  _c("nuxt-img", {
                    attrs: {
                      src: "/design/icons/google-play.svg",
                      alt: _vm.$t("common.googleplay_alt"),
                      title: _vm.$t("common.googleplay_title"),
                      laoding: "lazy",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "actions-main-header__download actions-main-header__download_app-store disabled",
                  attrs: {
                    "text-tooltip": _vm.$t("common.coming_soon"),
                    flow: "left",
                  },
                },
                [_c("i", { staticClass: "bx bxl-apple" })]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: { "header-burger": true, _active: _vm.hamburgerActive },
              attrs: { "aria-label": _vm.$t("common.menu") },
              on: { click: _vm.activateMenu },
            },
            [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }