<template>
  <header class="header">
    <div class="header-panel">
      <div class="header__container">
        <div class="header-panel__info info-header-panel">
          <div class="info-header-panel__el">
            <span class="info-header-panel__title">
              {{ $t('common.useful_links') }}: &nbsp;
            </span>
            <a href="/docs/coins_whitepaper.pdf" rel="nofollow noopener noreferrer" target="_blank" class="doc_link">
              {{ $t('common.whitepaper') }}
              <i class="bx bx-link-external"></i>
            </a>
            <a href="/docs/coins_onepager.pdf" rel="nofollow noopener noreferrer" target="_blank" class="doc_link">
              {{ $t('common.onepager') }}
              <i class="bx bx-link-external"></i>
            </a>
            <a href="/docs/coins_pitchdeck.pdf" rel="nofollow noopener noreferrer" target="_blank" class="doc_link">
              {{ $t('common.pitchdeck') }}
              <i class="bx bx-link-external"></i>
            </a>
            <a href="/docs/coins_roadmap.pdf" rel="nofollow noopener noreferrer" target="_blank" class="doc_link">
              {{ $t('common.roadmap') }}
              <i class="bx bx-link-external"></i>
            </a>
            <a href="/docs/coins_tokenomics.pdf" rel="nofollow noopener noreferrer" target="_blank" class="doc_link">
              {{ $t('common.tokenomics') }}
              <i class="bx bx-link-external"></i>
            </a>
          </div>
          <div class="info-header-panel__el">
            <span class="info-header-panel__title">
              {{ $t('common.marketcap') }}: &nbsp;
            </span>
            <span v-if="stats !== null && stats.length > 0 && !statsLoading" class="info-header-panel__value">
              {{ stats[5].value }}
            </span>
            <span v-else-if="statsLoading" class="info-header-panel__value">
              <Skeleton width="100px" height="12px" />
            </span>
            <span v-else class="info-header-panel__value">
              ...
            </span>
          </div>
          <div class="info-header-panel__el">
            <span class="info-header-panel__title">
              {{ $t('common.daily_volume') }}: &nbsp;
            </span>
            <span v-if="stats !== null && stats.length > 0 && !statsLoading" class="info-header-panel__value">
              {{ stats[6].value }}
            </span>
            <span v-else-if="statsLoading" class="info-header-panel__value">
              <Skeleton width="100px" height="12px" />
            </span>
            <span v-else class="info-header-panel__value">
              ...
            </span>
          </div>
          <div class="info-header-panel__el">
            <span class="info-header-panel__title">
              {{ $t('common.gas') }}: &nbsp;
            </span>
            <span v-if="stats !== null && stats.length > 0 && !statsLoading" class="info-header-panel__value">
              {{ gasPrice }}
            </span>
            <span v-else-if="statsLoading" class="info-header-panel__value">
              <Skeleton width="100px" height="12px" />
            </span>
            <span v-else class="info-header-panel__value">
              ...
            </span>
          </div>
        </div>

        <div class="header-panel__actions header-panel-actions">
          <div class="header-panel-actions__main-actions header-main-actions">
            <div class="header-main-actions__select-wrapper">
              <div :class="{'header-main-actions__el header-main-actions__el_select': true, '_active': dropdowns[0]}">
                <div class="header-main-actions__icon" @click="toggleDropdrown(0)">
                  <i class="bx bx-globe"></i>
                </div>
                <div :class="{'header-main-actions__name select': true, 'is-active': dropdowns[0]}">
                  <div class="select__header" @click="toggleDropdrown(0)">
                    <div class="select__current">
                      {{ $i18n.locale.name }}
                    </div>
                    <i class="bx bxs-down-arrow down-icon"></i>
                  </div>
                  <div class="select__body">
                    <div
                      v-for="locale in availableLocales"
                      :key="locale.code"
                      class="select__item"
                      @click="closeDropdowns"
                    >
                      <nuxt-link :to="switchLocalePath(locale.code)" rel="alternate" :hreflang="locale.code" :title="locale.name">
                        {{ locale.name }}
                      </nuxt-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="header-main-actions__select-wrapper">
              <div :class="{'header-main-actions__el header-main-actions__el_select': true, '_active': dropdowns[1]}">
                <div class="header-main-actions__icon" @click="toggleDropdrown(1)">
                  <CurrencyIcon />
                </div>
                <div :class="{'header-main-actions__name header-main-actions__name_currency select': true, 'is-active': dropdowns[1]}">
                  <div class="select__header" @click="toggleDropdrown(1)">
                    <div class="select__current">
                      {{ pickedCurrency.code.toUpperCase() }}
                    </div>
                    <i class="bx bxs-down-arrow down-icon"></i>
                  </div>
                  <div class="select__body">
                    <span v-for="(curr, ind) in currencies" :key="ind" class="select__item" @click="changeCurrency(curr.code)">
                      {{ curr.code.toUpperCase() }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <nuxt-link :to="localePath('/account')" class="header-main-actions__el header-main-actions__el">
              <div class="header-main-actions__icon">
                <i class="bx bxs-star"></i>
              </div>
              <span class="header-main-actions__name">
                {{ $t('common.watchlist') }}
              </span>
            </nuxt-link>

            <button type="button" class="header-main-actions__el header-main-actions__el_theme change-theme" :aria-label="$t('common.change_theme')" @click="changeTheme(null)">
              <span class="header-main-actions__icon">
                <DayNightIcon />
              </span>
              <span class="header-main-actions__name">
                {{ $t(`common.${theme}`) }}
              </span>
            </button>
          </div>
          <div v-if="user === null" class="header-panel-actions__log">
            <nuxt-link :to="localePath('/login')" class="header-panel-actions__button stroke-button">
              {{ $t('common.login') }}
            </nuxt-link>
            <nuxt-link :to="localePath('/register')" class="header-panel-actions__button filled-button">
              {{ $t('common.register') }}
            </nuxt-link>
          </div>
          <div v-else class="header-panel-actions__log">
            <nuxt-link :to="localePath('/account')" class="header-panel-actions__button filled-button">
              <i class="bx bx-user-circle"></i>
              {{ $t('common.my_account') }}
            </nuxt-link>
            <button type="button" class="header-panel-actions__button stroke-button" @click="logoutFromAccount">
              {{ $t('common.logout') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="header-main">
      <div class="header__container">
        <nuxt-link :to="localePath('/')" class="header-main__logo logo-header">
          <div class="logo-header__desktop">
            <nuxt-img
              v-if="theme === 'dark'"
              src="/design/logo-main-part-dark.svg"
              :alt="$t('common.logo_alt')"
              :title="$t('common.logo_title')"
              class="logo-header__img-dark"
            />
            <nuxt-img
              v-else
              src="/design/logo-main-part-light.svg"
              :alt="$t('common.logo_alt')"
              :title="$t('common.logo_title')"
              class="logo-header__img-light"
            />
          </div>
          <div class="logo-header__mobile">
            {{ $t('common.rating_of') }}
            <br>
            {{ $t('common.products') }}
          </div>
        </nuxt-link>

        <nav :class="{'header-main__navigation header-navigation': true, '_active': hamburgerActive}">
          <div class="header-navigation__head-panel burger-head-panel">
            <div class="burger-head-panel__actions">
              <div :class="{'header-main-actions__el header-main-actions__el_select': true, '_active': dropdowns[0]}">
                <div class="header-main-actions__icon" @click="toggleDropdrown(0)">
                  <i class="bx bx-globe"></i>
                </div>
                <div :class="{'header-main-actions__name select': true, 'is-active': dropdowns[0]}">
                  <div class="select__header" @click="toggleDropdrown(0)">
                    <div class="select__current">
                      {{ $i18n.locale.toUpperCase() }}
                    </div>
                    <i class="bx bxs-down-arrow down-icon"></i>
                  </div>
                  <div class="select__body">
                    <div
                      v-for="locale in availableLocales"
                      :key="locale.code"
                      class="select__item"
                      @click="closeDropdowns"
                    >
                      <nuxt-link :to="switchLocalePath(locale.code)" rel="alternate" :hreflang="locale.code" :title="locale.name">
                        {{ locale.code.toUpperCase() }}
                      </nuxt-link>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="{'header-main-actions__el header-main-actions__el_select': true, '_active': dropdowns[1]}">
                <div class="header-main-actions__icon" @click="toggleDropdrown(1)">
                  <CurrencyIcon />
                </div>
                <div :class="{'header-main-actions__name header-main-actions__name_currency select': true, 'is-active': dropdowns[1]}">
                  <div class="select__header" @click="toggleDropdrown(1)">
                    <div class="select__current">
                      {{ pickedCurrency.code.toUpperCase() }}
                    </div>
                    <i class="bx bxs-down-arrow down-icon"></i>
                  </div>
                  <div class="select__body">
                    <span v-for="(curr, ind) in currencies" :key="ind" class="select__item" @click="changeCurrency(curr.code)">
                      {{ curr.code.toUpperCase() }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="burger-head-panel__buttons">
              <button type="button" class="actions-main-header__download" :aria-label="$t('search.search')" @click="toggleSearch">
                <i class="bx bx-search"></i>
              </button>
              <nuxt-link :to="localePath('/account')" class="actions-main-header__download" :aria-label="$t('common.watchlist')">
                <i class="bx bxs-star"></i>
              </nuxt-link>
              <button class="header-main-actions__el header-main-actions__el_theme change-theme" :aria-label="$t('common.change_theme')" @click="changeTheme(null)">
                <span class="header-main-actions__icon">
                  <DayNightIcon />
                </span>
              </button>
            </div>
          </div>
          <Menu ref="mainMenu" :active="hamburgerActive" />
          <div class="header-navigation__bottom-panel bottom-panel-navigation">
            <div v-if="user === null" class="bottom-panel-navigation__log">
              <nuxt-link :to="localePath('/register')" class="header-panel-actions__button stroke-button">
                {{ $t('common.create_account') }}
              </nuxt-link>
              <nuxt-link :to="localePath('/login')" class="header-panel-actions__button filled-button">
                {{ $t('common.login') }}
              </nuxt-link>
            </div>
            <div v-else class="bottom-panel-navigation__log">
              <nuxt-link :to="localePath('/account')" class="header-panel-actions__button filled-button">
                <i class="bx bx-user-circle"></i>
                {{ $t('common.my_account') }}
              </nuxt-link>
              <button type="button" class="header-panel-actions__button stroke-button" @click="logoutFromAccount">
                {{ $t('common.logout') }}
              </button>
            </div>
            <div class="bottom-panel-navigation__buttons">
              <span class="bottom-panel-navigation__title">
                {{ $t('common.download') }}
                <br>
                {{ $t('common.app') }}
              </span>
              <a href="https://play.google.com/store/apps/details?id=com.coins_rating.twa" rel="nofollow noopener noreferrer" target="_blank" class="bottom-panel-navigation__button" :aria-label="$t('common.download_app_google_play')">
                <i class="bx bxl-play-store"></i>
              </a>
              <div class="bottom-panel-navigation__button disabled" :text-tooltip="$t('common.coming_soon')" flow="left">
                <i class="bx bxl-apple"></i>
              </div>
            </div>
          </div>
        </nav>

        <div class="header-main__actions actions-main-header">
          <SearchPanel :active="searchActive" @toggle="toggleSearch" />
          <a href="https://play.google.com/store/apps/details?id=com.coins_rating.twa" rel="nofollow noopener noreferrer" target="_blank" class="actions-main-header__download" :aria-label="$t('common.download_app_google_play')">
            <nuxt-img
              src="/design/icons/google-play.svg"
              :alt="$t('common.googleplay_alt')"
              :title="$t('common.googleplay_title')"
              laoding="lazy"
            />
          </a>
          <div class="actions-main-header__download actions-main-header__download_app-store disabled" :text-tooltip="$t('common.coming_soon')" flow="left">
            <i class="bx bxl-apple"></i>
          </div>
        </div>

        <button :class="{'header-burger': true, '_active': hamburgerActive}" :aria-label="$t('common.menu')" @click="activateMenu">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Skeleton } from 'vue-loading-skeleton'
import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

import CurrencyIcon from '~/components/misc/CurrencyIcon'
import DayNightIcon from '~/components/misc/DayNightIcon'

export default {
  name: 'HeaderComponent',
  components: {
    CurrencyIcon,
    DayNightIcon,
    Menu: hydrateWhenIdle(() => import('~/components/common/Menu')),
    SearchPanel: hydrateWhenIdle(() => import('~/components/modals/SearchPanel')),
    Skeleton
  },
  data() {
    const currency = this.$cookies.get('siteCurrency') !== undefined ? this.$cookies.get('siteCurrency') : 'usd'
    return {
      currency,
      dropdowns: [false, false],
      hamburgerActive: false,
      searchActive: false
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
      meta: [
        {
          hid: 'og-image',
          name: 'og:image',
          content: 'seo/default.jpg'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['currencies', 'theme']),
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('seos', [
      'stats',
      'statsLoading'
    ]),
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
    gasPrice() {
      if (this.stats !== null && this.stats.length > 0) {
        try {
          const prices = JSON.parse(this.stats[7].value)
          return (parseInt(prices.standard, 10) / 10) + ' Gwei'
        } catch (err) {
          return '...'
        }
      } else {
        return '...'
      }
    },
    pickedCurrency() {
      return this.currencies.find(itm => itm.code.toLowerCase() === this.currency.toLowerCase())
    }
  },
  watch: {
    $route(to, from) {
      if (this.hamburgerActive) {
        this.activateMenu()
      }
    }
  },
  beforeMount() {
    if (document.querySelector('.menu') !== null && document.querySelector('.menu').classList
      .contains('active')) {
      document.querySelector('.menu').classList.remove('active')
      document.querySelector('body').classList.remove('active')
    }
  },
  mounted() {
    this.fetchStats()
    window.addEventListener('click', this.handleClicks)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClicks)
  },
  methods: {
    ...mapActions('auth', [
      'logout'
    ]),
    ...mapActions('seos', [
      'fetchStats'
    ]),
    async logoutFromAccount() {
      await this.logout().then(() => {
        this.activateMenu()
        this.$clearCookies()
        this.$router.push({
          path: this.localePath('/login')
        })
      })
    },
    activateMenu() {
      this.hamburgerActive = !this.hamburgerActive
      if (this.$refs.mainMenu !== undefined && this.$refs.mainMenu !== null) {
        const method = this.hamburgerActive ? 'add' : 'remove'
        this.$refs.mainMenu.$el.classList[method]('_active')
        document.querySelector('body').classList[method]('scroll-none')
      }
    },
    toggleSearch() {
      this.searchActive = !this.searchActive
      const method = this.searchActive ? 'add' : 'remove'
      if (this.hamburgerActive) {
        this.activateMenu()
      }
      document.querySelector('body').classList[method]('scroll-none')
    },
    changeTheme(forceTheme = null) {
      this.$changeTheme(forceTheme)
    },
    changeCurrency(currencyCode = 'usd') {
      if (currencyCode !== undefined && this.currency.toLowerCase() !== currencyCode.toLowerCase()) {
        this.currency = currencyCode.toLowerCase()
        this.$changeCurrency(this.currency)
        this.closeDropdowns()
        this.$nuxt.$emit('changeCurrency', this.currency)
      }
    },
    toggleDropdrown(dropdownIndex) {
      if (this.dropdowns[dropdownIndex] !== undefined) {
        this.closeDropdowns()
        this.$set(this.dropdowns, dropdownIndex, true)
      }
    },
    closeDropdowns() {
      for (let ind = 0; ind < this.dropdowns.length; ++ind) {
        this.$set(this.dropdowns, ind, false)
      }
    },
    handleClicks() {
      window.addEventListener('click', (evt) => {
        if (!evt.target.closest('nav') &&
            !evt.target.closest('.header-burger') &&
            this.$refs.mainMenu !== undefined &&
            this.$refs.mainMenu !== null &&
            this.hamburgerActive) {
          this.activateMenu()
        }
        if (
          !evt.target.closest('.search-area') &&
          !evt.target.closest('.search-root') &&
          !evt.target.closest('.header-search__button') &&
          !evt.target.closest('.header-search__input') &&
          !evt.target.closest('.header-search__button') &&
          !evt.target.closest('.search-icon') &&
          !evt.target.closest('.icon-close') &&
          !evt.target.closest('.reveal-search') &&
          !evt.target.closest('.actions-main-header__download') &&
          this.searchActive === true
        ) {
          this.toggleSearch()
        }
        if (
          !evt.target.closest('.header-main-actions__el_select') &&
          !evt.target.closest('.select__body') &&
          !evt.target.closest('.select__current') &&
          !evt.target.closest('.select__header')
        ) {
          this.closeDropdowns()
        }
      })
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/common/header.css');
</style>
